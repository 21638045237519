<template>
  <div class="row justify-content-center">
    <div class="col-md-8">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <h3 class="mb-0">Dados do Contrato</h3>
          </div>
        </div>
        <div class="card-body">
          <form>
            <div class="form-group  row">
              <div class="form-group  col-md-12">
                <label class="col-md-12">Nome:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.nome"
                  placeholder="Digite o Nome"
                />
              </div>
              <div class="col-md-6">
                <label class="col-md-12">Tipo do Contrato:*</label>
                <select
                  required
                  type="text"
                  class="form-control"
                  v-model="form.tipo_produto_id"
                  placeholder="Digite Tipo de Produto"
                >
                  <option
                    v-for="lista in lista_tipos_produto"
                    :key="lista.id"
                    :value="lista.id"
                  >
                    {{ lista.nome }}
                  </option>
                </select>
              </div>
                <!-- <div class="col-md-6">
                <label class="col-md-12">Valor:*</label>
                <input
                  required
                  type="text"
                  class="form-control"
                  v-model="form.valor"
                  placeholder="Digite o Nome"
                />
              </div> -->
            </div>
            <div class="form-group row">
              <div class="col-md-12">
                <label class="col-md-12">Descrição:*</label>
                <textarea
                
                  required
                  type="text"
                  class="form-control"
                  v-model="form.descricao"
                  placeholder="Digite a descrição"
                />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-12 text-center">
                <button
                  @click.prevent="confirm(!lista_campos.id ? 'cria' : 'edita')"
                  class="btn btn-primary"
                  :disabled="verif"
                >
                  Salvar
                  <b-spinner
                    v-show="verif"
                    small
                    variant="dark"
                    type="grow"
                    label="Spinning"
                  ></b-spinner>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";

export default {
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Contratos" }]);
  },
  mixins: [fireAlert],
  data() {
    return {
      form: {
        nome: "",
        tipo_produto_id: null,
        descricao: "",
        // valor:null
      },

      verif: false,
      currentPage: 1,
      perPage: 10,
      editarProduto: true,
    };
  },
  created() {
    this.listar_requerimentos();
  },
  computed: {
    lista_tipos_produto() {
      return this.$store.state.produto.lista_tipos_produto
    },

    lista_campos() {
      // return JSON.parse(localStorage.getItem("produto"));
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.produto.mensagem_alert;
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` um Produto no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      await this.$store.dispatch("produto/create_produto", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "produto",
      });
    },
    async update() {
        this.verif = true;
      await this.$store.dispatch("produto/update_produto", this.form);
      this.verif = false;

        this.fireAlert({
        ...this.mensagem_alert,
        routeName: "produto",
      });
    },

    async listar_requerimentos() {
      await this.$store.dispatch("produto/listar_tipos_produto").finally(() => {
            this.preenxerCampos();
      });
    },
    async preenxerCampos() {
      console.log(this.lista_campos);

    if (this.lista_campos != "")
        this.form = {
          id: this.lista_campos.id,
          nome: this.lista_campos.nome,
          status: this.lista_campos.status,
          // valor: this.lista_campos.valor,
          tipo_produto_id: this.lista_campos.tipo_produto_id,
          descricao: this.lista_campos.descricao,

          user_up: this.lista_campos.user_up,
        };
    }
    }
    
  

};
</script>

<style scoped>
.lista:hover {
  background-color: rgb(167, 183, 255);
}
</style>